import React from "react";
import CheckmarkSVG from "assets/icons/checkmark.inline.svg";

import Icon from "./Icon";

const Checkmark = ({ ...rest }) => (
	<Icon
		name="checkmark"
		alt="checkmark"
		{...rest}
	>
		<CheckmarkSVG />
	</Icon>
);

export default Checkmark;
