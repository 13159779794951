import React from "react";
import PhoneSVG from "assets/icons/phone.inline.svg";

import Icon from "./Icon";

const Phone = ({ ...rest }) => (
	<Icon
		name="phone"
		alt="Phone"
		{...rest}
	>
		<PhoneSVG />
	</Icon>
);

export default Phone;
