import React, { useState } from "react";
import "./ShowNumber.scss";
import { Link } from "components/core/Link";
import PropTypes from "prop-types";
import { LoadingBoxes, Phone } from "components/core/Icons";
import { Text } from "components/core/Typography";

function ShowNumber({ color, underline, showIcon, phone, handleFetchNumber }) {
	const [showNumber, setShowNumber] = useState();
	const [isLoading, setIsLoading] = useState(false);

	return (
		<div>
			{showNumber && phone ? (
				<Link
					last
					size="small"
					color={color}
					hover={underline}
					underline={phone.formattedNumber && underline}
					href={`tel:${phone.number}`}
					weight="semiBold"
					className="dial-number-link"
					segmentLabel="click to call"
					icon={
						showIcon && (
							<Phone
								data-testid="phone-icon"
								size="xlarge"
							/>
						)
					}
				>
					{isLoading && !phone.formattedNumber && (
						<span className="fetch-phone-loading">
							<LoadingBoxes
								size="large"
								primaryColor="blue"
							/>
						</span>
					)}
					{phone.formattedNumber}
				</Link>
			) : (
				<Text
					color={color}
					size="small"
					weight="semiBold"
					className="custom-label"
					onClick={() => {
						setIsLoading(true);
						handleFetchNumber("");
						setShowNumber(true);
					}}
				>
					{showIcon && (
						<Phone
							data-testid="phone-icon"
							size="xlarge"
						/>
					)}
				</Text>
			)}
		</div>
	);
}

ShowNumber.propTypes = {
	showIcon: PropTypes.bool,
	underline: PropTypes.bool,
	color: PropTypes.oneOf(["blue", "white"]),
	phone: PropTypes.shape({
		formattedNumber: PropTypes.string,
		number: PropTypes.string
	}),
	handleFetchNumber: PropTypes.func
};

export default ShowNumber;
