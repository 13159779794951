import { schema } from "normalizr";

const reviewSchema = new schema.Entity("reviews");

// Reviews grouped by facility id
const facilityReviewsEntity = new schema.Entity(
	"facilityReviews",
	{ reviews: [reviewSchema] },
	{
		idAttribute: (value, parent, key) => key,
		processStrategy: (entity) => ({ reviews: entity })
	}
);

export const facilityReviewsSchema = new schema.Values(facilityReviewsEntity);

export default reviewSchema;
