import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { withSegmentContext } from "@sparefoot/react-commons/segment";

import "./Section.scss";

const enhance = withSegmentContext;

export function Section({ children, className, id, flush = false }) {
	return (
		<section
			id={id}
			className={classnames("page-section", className, { flush })}
		>
			{children}
		</section>
	);
}

Section.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	id: PropTypes.string,
	flush: PropTypes.bool
};

export default enhance(Section);
