/* eslint-disable prefer-const */
import { createAsyncThunk } from "@reduxjs/toolkit";
import callAPI from "utils/api";

export function createAPIAction({ actionName, uri, schema, queryDefaults }) {
	return createAsyncThunk(
		actionName,
		async ({ query, path = "", method, body }, { getState }) => {
			// this first argument contains whatever our action creator passes us
			const state = getState();
			let {
				app: { apiHost, apiToken, id },
				user: {
					visitId,
					siteId,
					// eslint-disable-next-line camelcase
					traffic_source_id,
					// eslint-disable-next-line camelcase
					user_ip
				}
			} = state;

			const headers = {
				token: apiToken,
				"Content-Type": "application/json",
				"app-id": id,
				"site-id": siteId,
				"visit-id": visitId,
				"traffic-source-id": traffic_source_id,
				// cid: user.cid,
				// ctid: user.ctid,
				"x-is-bot": visitId === "bot" || visitId === "error" ? 1 : 0,
				"x-sparefoot-ip": user_ip
			};
			const options = {
				headers,
				method,
				body,
				query: {
					...queryDefaults,
					...query
				}
			};

			const url = `${apiHost}${uri}${path}`;
			const response = await callAPI({
				url,
				options,
				schema
			});

			return response;
		}
	);
}
