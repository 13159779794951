import React from "react";
import DocumentSVG from "assets/icons/document.inline.svg";

import Icon from "./Icon";

const Document = ({ ...rest }) => (
	<Icon
		name="document"
		alt="document"
		{...rest}
	>
		<DocumentSVG />
	</Icon>
);

export default Document;
