import Segment from "@sparefoot/segment-react";
import { initialize } from "store/create";
import { initializeGoogle } from "utils/search/autocomplete";

export default ({ dispatch, getState }) => {
	const { app, user } = getState();

	Segment.initialize(app.segmentWriteKey, {
		siteId: 424,
		visitId: user.visitId,
		environment: app.environment,
		siteType: "desktop",
		host: app.baseUrl.replace(/^https?:\/\//i, ""),
		debug: app.environment === "development"
	});

	// store the visit ID
	Segment.identify(user.visitId);

	initializeGoogle({
		key: app.googleMapsKey
	});

	dispatch(initialize());
};
