import React from "react";
import QuestionSignSVG from "assets/icons/question-sign.inline.svg";

import Icon from "./Icon";

const QuestionSign = ({ ...rest }) => (
	<Icon
		name="question-sign"
		alt="Question sign"
		{...rest}
	>
		<QuestionSignSVG />
	</Icon>
);

export default QuestionSign;
