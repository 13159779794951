import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Expand } from "@sparefoot/react-commons";
import { Button } from "components/core/Button";
import { Link } from "components/core/Link";
import { Chevron } from "components/core/Icons";

import "./HeaderMenu.scss";

function HeaderMenu({
	menuName,
	links,
	className,
	openMenuName,
	setOpenMenuName,
	isStacked
}) {
	const [expanded, setExpanded] = useState(false);

	const handleExpand = () => {
		setExpanded(!expanded);
		setOpenMenuName(menuName);
	};

	useEffect(() => {
		if (menuName !== openMenuName) setExpanded(false);
	}, [openMenuName]);

	return (
		<div
			className={classnames(
				"header-menu",
				{ stacked: isStacked },
				className
			)}
		>
			<Button
				title={`${expanded ? "Close" : "Open"} ${menuName} menu`}
				onClick={handleExpand}
				className={classnames("button-menu", {
					"button-expanded": expanded,
					"button-collapsed": !expanded
				})}
				segmentLabel={`${menuName} menu ${
					expanded ? "open" : "closed"
				}`}
				withIcon
			>
				{menuName}
				<Chevron rotate={expanded ? "up" : "down"} />
			</Button>
			<Expand expanded={expanded}>
				<div>
					{links.map(({ href, text, submenu }) => (
						<Fragment key={`fragment-${text}`}>
							<Link
								last
								className={submenu ? "link-menu-header":"link-menu"}
								href={href}
								key={text}
								segmentLabel={text}
							>
								{text}
							</Link>
							{submenu &&
								submenu.map((sub) => (
									<Link
										last
										color="lightBlue"
										className="link-submenu"
										href={sub.href}
										key={sub.text}
										segmentLabel={sub.text}
									>
										{sub.text}
									</Link>
								))}
						</Fragment>
					))}
				</div>
			</Expand>
		</div>
	);
}

HeaderMenu.propTypes = {
	isStacked: PropTypes.bool,
	menuName: PropTypes.string,
	links: PropTypes.arrayOf(PropTypes.object).isRequired,
	className: PropTypes.string,
	openMenuName: PropTypes.string,
	setOpenMenuName: PropTypes.func
};

export default HeaderMenu;
