import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { get } from "lodash";
import { getSearchPage, getSearchResults } from "store/modules/search";
import mergeEntities from "utils/store/mergeEntities";
import { createAPIAction } from "store/actions/api";
import facilitySchema from "store/schema/facility";

// =============================================================================
// INITIAL STATE
// =============================================================================

export const initialState = {
	loading: false,
	error: null,
	statusCode: null,
	currentFacility: {},
	phone: {},
	byId: {}
};

// =============================================================================
// REDUCER
// =============================================================================

const reducers = {};

// =============================================================================
// THUNKS
// =============================================================================

export const getFacilityPage = createAPIAction({
	actionName: "facilities/getFacilityPage",
	uri: "/api/facility",
	schema: facilitySchema
});

// =============================================================================
// EXTRA REDUCERS
// =============================================================================

const extraReducers = (builder) => {
	builder
		.addMatcher(isAnyOf(getFacilityPage.pending), (state) => {
			state.loading = true;
		})
		.addMatcher(isAnyOf(getFacilityPage.fulfilled), (state, action) => {
			const { result, meta } = action.payload;
			
			const {noIndex, title, descrption, keywords, canonical} = meta;

			// clear loading, errors
			state.loading = false;
			state.error = false;

			// store all the facilites we got
			const facilities = get(action, "payload.entities.facilities", {});
			state.byId = mergeEntities(state.byId, facilities);

			// store the info about the looked up facility (result)
			state.currentFacility = {
				id: result,
				title,
				descrption,
				keywords,
				canonical,
				noIndex
			};
		})
		.addMatcher(isAnyOf(getFacilityPage.rejected), (state) => {
			state.loading = false;
			state.error = true;
		})
		.addMatcher(isAnyOf(getSearchPage.fulfilled), (state, action) => {
			const facilities = get(action, "payload.entities.facilities", {});
			state.byId = mergeEntities(state.byId, facilities);
		})
		.addMatcher(
			isAnyOf(getSearchResults.fulfilled, getSearchPage.fulfilled),
			(state, action) => {
				const newFacilities = get(
					action,
					"payload.entities.facilities",
					{}
				);
				const mergedFacilities = mergeEntities(
					state.byId,
					newFacilities
				);
				state.byId = mergedFacilities;
			}
		);
};

// =============================================================================
// SLICE
// =============================================================================

export const facilitiesSlice = createSlice({
	name: "facilities",
	initialState,
	reducers,
	extraReducers
});

export default facilitiesSlice.reducer;
