import { useLayoutEffect, useState } from "react";
import { capitalize } from "lodash";

export const BREAKPOINTS = {
	XSMALL: 0,
	SMALL: 320,
	MEDIUM: 768,
	LARGE: 1272
};

/**
 * JSDOM doesn't support matchMedia, which makes this very impractical to test.
 */

const MEDIA_QUERIES_LIST = Object.keys(BREAKPOINTS).reduce(
	(acc, val, idx, arr) => {
		// low bound is inclusive, high bound is exclusive
		const lowBound = BREAKPOINTS[val];
		// one minus the next breakpoint'
		const highBound = arr[idx + 1] ? BREAKPOINTS[arr[idx + 1]] - 1 : null;
		const breakpointName = capitalize(val);
		return {
			...acc,
			[`is${breakpointName}`]: `(min-width: ${lowBound}px)${
				highBound ? ` and (max-width: ${highBound}px)` : ""
			}`,
			[`above${breakpointName}`]: `(min-width: ${lowBound}px)`,
			[`below${breakpointName}`]: `(max-width: ${lowBound - 1}px)`
		};
	},
	{}
);

// e.g. [isLarge, aboveLarge, belowLarge ]
export const mediaQueries = Object.fromEntries(
	Object.keys(MEDIA_QUERIES_LIST).map((key) => [key, key])
);

export const useMediaQuery = (mediaQueryNameList, defaultMatches = []) => {
	const initialMatches = defaultMatches.length
		? defaultMatches
		: Array(mediaQueryNameList.length).fill(false);
	/* istanbul ignore next */
	if (typeof window === "undefined") return initialMatches;

	const mediaQueriesLists = mediaQueryNameList.map((name) =>
		window.matchMedia(MEDIA_QUERIES_LIST[name])
	);

	const getMatchedValues = () => {
		const matchedMediaQueries = mediaQueriesLists.map((mql) => mql.matches);
		return matchedMediaQueries;
	};

	const [matchedValues, setMatchedValues] = useState(getMatchedValues);

	/* istanbul ignore next */
	useLayoutEffect(() => {
		const handleChange = () => {
			setMatchedValues(getMatchedValues);
		};
		mediaQueriesLists.forEach((mql) =>
			mql.addEventListener("change", handleChange)
		);
		return () =>
			mediaQueriesLists.forEach((mql) =>
				mql.removeEventListener("change", handleChange)
			);
	}, []);

	return matchedValues;
};

export default useMediaQuery;
