import { get, isEmpty } from "lodash";
import { setExperimentsBucket } from "@sparefoot/react-commons/modules/experiments";

export default (store) => (next) => (action) => {
	/**
	 * This middleware looks for actions with a payload with an experiments key.
	 * The fetchDefaultPage action will get data from the server that includes
	 * experiment data. If this middleware encounters it, it will dispatch
	 * our setExperimentsBucket action to log those experiments.
	 *
	 * All of this should run _before_ the FE is hydrated.
	 *
	 */

	const { user } = store.getState();
	const experiments = get(action, "payload.experiments");
	if (!isEmpty(experiments) && user.visitId !== "SELENIUM") {
		store.dispatch(setExperimentsBucket(experiments));
	}
	return next(action);
};
