import { createEntityAdapter, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { get } from "lodash";
import mergeEntities from "utils/store/mergeEntities";
import { createAPIAction } from "store/actions/api";
import bookingSchema from "store/schema/booking";

// =============================================================================
// INITIAL STATE
// =============================================================================

const bookingAdapter = createEntityAdapter({
	selectId: (booking) => booking.confirmationCode
});

export const initialState = bookingAdapter.getInitialState({
	loading: false,
	error: null,
	statusCode: null,
	currentBooking: {},
	byId: {}
});

// =============================================================================
// REDUCER
// =============================================================================

const reducers = {};

// =============================================================================
// THUNKS
// =============================================================================

export const getBookingDetails = createAPIAction({
	actionName: `bookings/getBookingDetails/`,
	uri: `/api/booking/`,
	schema: bookingSchema
});

export const createBooking = createAPIAction({
	actionName: "bookings/createBooking",
	uri: "/api/booking",
	schema: bookingSchema
});

export const updateBooking = createAPIAction({
	actionName: "bookings/updateBooking",
	uri: "/api/booking",
	schema: bookingSchema
});

export const deleteBooking = createAPIAction({
	actionName: "bookings/deleteBooking",
	uri: "/api/booking",
	schema: bookingSchema
});

// =============================================================================
// EXTRA REDUCERS
// =============================================================================

const extraReducers = (builder) => {
	builder
		.addMatcher(isAnyOf(getBookingDetails.pending), (state) => {
			state.loading = true;
		})
		.addMatcher(isAnyOf(getBookingDetails.fulfilled), (state, action) => {
			const { result, meta } = action.payload;

			// Clear loading and errors
			state.loading = false;
			state.error = false;

			// Store the booking details
			const booking = get(action, "payload.entities.bookings", {});
			state.byId = mergeEntities(state.byId, booking);

			// Store the info about the current booking
			state.currentBooking = {
				id: result,
				...meta
			};
		})
		.addMatcher(isAnyOf(getBookingDetails.rejected), (state) => {
			state.loading = false;
			state.error = true;
		})
		.addMatcher(isAnyOf(createBooking.pending), (state) => {
			state.loading = true;
		})
		.addMatcher(isAnyOf(createBooking.fulfilled), (state, action) => {
			const { result, meta } = action.payload;

			// Access the created booking data from the action payload
			const booking = get(action, "payload.entities.bookings", {});
			state.byId = mergeEntities(state.byId, booking);

			state.currentBooking = {
				id: result,
				...meta
			};

			// Clear loading and errors
			state.loading = false;
			state.error = false;
		})
		.addMatcher(isAnyOf(createBooking.rejected), (state) => {
			state.loading = false;
			state.error = true;
		})
		.addMatcher(isAnyOf(updateBooking.pending), (state) => {
			state.loading = true;
		})
		.addMatcher(isAnyOf(updateBooking.fulfilled), (state, action) => {
			const { result, meta } = action.payload;

			// Access the created booking data from the action payload
			const booking = get(action, "payload.entities.bookings", {});
			state.byId = mergeEntities(state.byId, booking);

			state.currentBooking = {
				id: result,
				...meta
			};

			// Clear loading and errors
			state.loading = false;
			state.error = false;
		})
		.addMatcher(isAnyOf(updateBooking.rejected), (state) => {
			state.loading = false;
			state.error = true;
		})
		.addMatcher(isAnyOf(deleteBooking.pending), (state) => {
			state.loading = true;
		})
		.addMatcher(isAnyOf(deleteBooking.fulfilled), (state, action) => {
			const { result, meta } = action.payload;

			// Access the created booking data from the action payload
			const booking = get(action, "payload.entities.bookings", {});
			state.byId = mergeEntities(state.byId, booking);

			state.currentBooking = {
				id: result,
				...meta
			};

			// Clear loading and errors
			state.loading = false;
			state.error = false;
		})
		.addMatcher(isAnyOf(deleteBooking.rejected), (state) => {
			state.loading = false;
			state.error = true;
		});
};

// =============================================================================
// SLICE
// =============================================================================

export const bookingsSlice = createSlice({
	name: "bookings",
	initialState,
	reducers,
	extraReducers
});

export default bookingsSlice.reducer;
