import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { withSegmentContext } from "@sparefoot/react-commons/segment";
import { Schema } from "@sparefoot/react-commons";
import {
	useCoreWebVitalMetrics,
	useGTMPageViewEvent
} from "@sparefoot/react-commons/hooks";
import { metaPropTypes } from "config/propTypes";
import { Head } from "components/core/Head";
import { TrackingPixel } from "components/core/TrackingPixel";
import { websiteSchema, organizationSchema } from "config/schemas";

import "./Page.scss";

const enhance = withSegmentContext;

const GlobalPageSchemas = () => (
	<>
		<Schema schema={websiteSchema} />
		<Schema schema={organizationSchema} />
	</>
);
export function Page({
	children,
	className,
	verticalSpacing,
	title,
	description,
	canonical,
	noIndex,
	trackingPixelUrl,
	pageType
}) {
	useGTMPageViewEvent(pageType);
	useCoreWebVitalMetrics(pageType);
	return (
		<Fragment>
			<Head
				title={title}
				description={description}
				canonical={canonical}
				noIndex={noIndex}
			/>
			<div
				className={classnames("page", className, {
					"vertical-spacing": verticalSpacing
				})}
			>
				{children}
			</div>
			<GlobalPageSchemas />
			{!!trackingPixelUrl && <TrackingPixel src={trackingPixelUrl} />}
		</Fragment>
	);
}
Page.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	verticalSpacing: PropTypes.bool,
	...metaPropTypes
};

export default enhance(Page);
