import { createEntityAdapter, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { createAPIAction } from "store/actions/api";
import stateSchema from "store/schema/state";

// =============================================================================
// INITIAL STATE
// =============================================================================

const stateAdapter = createEntityAdapter({
	selectId: (state) => state.contentId
});

export const initialState = stateAdapter.getInitialState({
	loading: false,
	error: null,
	statusCode: null,
	currentState: {}
});

// =============================================================================
// REDUCER
// =============================================================================

const reducers = {};

// =============================================================================
// THUNKS
// =============================================================================

export const getStateLandingPage = createAPIAction({
	actionName: `state/getStateLandingPage/`,
	uri: `/api/content/`,
	schema: stateSchema
});

// =============================================================================
// EXTRA REDUCERS
// =============================================================================

const extraReducers = (builder) => {
	builder
		.addMatcher(isAnyOf(getStateLandingPage.pending), (state) => {
			state.loading = true;
		})
		.addMatcher(isAnyOf(getStateLandingPage.fulfilled), (state, action) => {
			const { result, meta } = action.payload;
			// Clear loading and errors
			state.loading = false;
			state.error = false;

			// Store the info about the current state
			state.currentState = {
				...result,
				...meta
			};
		})
		.addMatcher(isAnyOf(getStateLandingPage.rejected), (state) => {
			state.loading = false;
			state.error = true;
		});
};

// =============================================================================
// SLICE
// =============================================================================

export const stateSlice = createSlice({
	name: "stateData",
	initialState,
	reducers,
	extraReducers
});

export default stateSlice.reducer;
