import React from "react";
import PropTypes from "prop-types";
import { Text } from "components/core/Typography";
import Button from "./Button";

import "./Button.scss";

const TextButton = ({ icon, children, color, reverse, ...rest }) => (
	<Button
		theme="text"
		reverse={reverse}
		{...rest}
	>
		{icon && React.cloneElement(icon, { fill: color })}
		<Text
			last
			size="small"
			color={color}
		>
			{children}
		</Text>
	</Button>
);

TextButton.defaultProps = {
	flush: false,
	color: "lightGray",
	reverse: false
};

TextButton.propTypes = {
	color: PropTypes.string,
	icon: PropTypes.node,
	flush: PropTypes.bool,
	children: PropTypes.node,
	reverse: PropTypes.bool
};

export default TextButton;
