import "isomorphic-unfetch";
import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { getFacilityPage } from "./facilities";

export const initialState = {
	baseUrl: "",
	apiHost: "",
	trackingUrl: "",
	trackingId: "",
	id: null,
	segmentWriteKey: null,
	statusCode: 200,
	experiments: {},
	status: null,
	error: null,
	ready: false
};

export function createPageAction({ actionName, uri }) {
	return createAsyncThunk(actionName, async (_id, thunkAPI) => {
		const state = thunkAPI.getState();
		const {
			app: { apiHost, apiToken, id },
			user: { visitId }
		} = state;
		const response = await fetch(`${apiHost}${uri}`, {
			headers: {
				token: apiToken,
				"app-id": id,
				"visit-id": visitId
			}
		});
		return response.json();
	});
}

export const fetchDefaultPage = createPageAction({
	actionName: "app/fetchDefaultPage",
	uri: "/api/page/default"
});

export const appSlice = createSlice({
	name: "app",
	initialState,
	reducers: {
		setAppReady: (state) => {
			state.ready = true;
		},
		renderError: (state, action) => {
			state.statusCode = 500;
			state.loading = false;
			state.error = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchDefaultPage.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchDefaultPage.fulfilled, (state, action) => {
				state.status = "loaded";
				state.experiments = action.payload.experiments;
			})
			.addMatcher(isAnyOf(getFacilityPage.rejected), (state, action) => {
				state.statusCode = 404;
				state.loading = false;
				state.error = action?.error?.message || "Error loading page";
			});
	}
});

export const { renderError, setAppReady } = appSlice.actions;

export default appSlice.reducer;
