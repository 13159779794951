import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./Icon.scss";

const ICON_ROTATIONS = {
	UP: "up",
	RIGHT: "right",
	DOWN: "down",
	LEFT: "left"
};

const ICON_SIZES = {
	XSMALL: "xsmall",
	SMALL: "small",
	MEDIUM: "medium",
	LARGE: "large",
	XLARGE: "xlarge",
	XXLARGE: "xxlarge",
	HUGE: "huge",
	FILL: "fill"
};

const ICON_COLORS = {
	// these match the colors defined in _variables.scss
	BLACK: "black",
	BLUE: "blue",
	DARK_GRAY: "darkGray",
	GRAY: "gray",
	GREEN: "green",
	LIGHT_GRAY: "lightGray",
	LIGHT_BLUE: "lightBlue",
	LIGHTEST_BLUE: "lightestBlue",
	WHITE: "white",
	DARK_BLUE: "darkBlue",
	ORANGE: "orange",
	LIGHTEST_ORANGE: "lightestOrange",
	RED: "red",
	CURRENT_COLOR: "currentColor"
};

const Icon = ({
	name,
	fill,
	rotate,
	size,
	animate,
	children,
	alt,
	...rest
}) => (
	<i
		className={classnames(
			{ animate },
			"sf-icon",
			`sf-${name}`,
			`fill-${fill}`,
			`rotate-${rotate}`,
			`size-${size}`
		)}
		title={alt}
		{...rest}
	>
		{children}
	</i>
);

Icon.defaultProps = {
	size: ICON_SIZES.MEDIUM,
	fill: ICON_COLORS.CURRENT_COLOR,
	rotate: ICON_ROTATIONS.UP,
	animate: false,
	name: "icon",
	alt: "Icon"
};

Icon.propTypes = {
	children: PropTypes.node,
	animate: PropTypes.bool,
	name: PropTypes.string,
	alt: PropTypes.string,
	size: PropTypes.oneOf(Object.values(ICON_SIZES)),
	fill: PropTypes.oneOf(Object.values(ICON_COLORS)),
	rotate: PropTypes.oneOf(["up", "down", "left", "right"])
};

export default Icon;
