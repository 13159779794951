const amenitiesFilterOptions = [
	{
		label: "Alarm",
		value: "alarm"
	},
	{
		label: "Climate Controlled",
		value: "climateControlled"
	},
	{
		label: "Drive Up Access",
		value: "driveUp"
	},
	{
		label: "1st Floor",
		value: "firstFloor"
	},
	{
		label: "24-Hour Access",
		value: "twentyFourHourAccess"
	},
	{
		label: "Free Truck Rental",
		value: "freeTruckRental"
	},
	{
		label: "Open Now",
		value: "openNow"
	}
];

const unitSizeFilterOptions = [
	{
		label: "5' x 5'",
		value: "0-25"
	},
	{
		label: "5' x 10'",
		value: "26-60"
	},
	{
		label: "5' x 15'",
		value: "61-75"
	},
	{
		label: "10' x 10'",
		value: "76-100"
	},
	{
		label: "10' x 15'",
		value: "101-150"
	},
	{
		label: "10' x 20'",
		value: "151-200"
	},
	{
		label: "10' x 25'",
		value: "201-250"
	},
	{
		label: "10' x 30'",
		value: "251-300"
	}
];

export const FILTER_GROUPS = {
	AMENITIES: "amenities",
	UNIT_SIZE: "sqft"
};

const optionsByValue = (options, filterGroupKey) =>
	options.reduce(
		(acc, { label, value }) => ({
			...acc,
			[value]: {
				label,
				checked: false,
				name: filterGroupKey,
				value
			}
		}),
		{}
	);

const defaultFilterOptions = {
	[FILTER_GROUPS.AMENITIES]: {
		label: "Amenities Filters",
		type: "checkbox",
		multiple: true,
		options: optionsByValue(amenitiesFilterOptions, FILTER_GROUPS.AMENITIES)
	},
	[FILTER_GROUPS.UNIT_SIZE]: {
		label: "Unit Size Filters",
		type: "checkbox",
		multiple: false,
		options: optionsByValue(unitSizeFilterOptions, FILTER_GROUPS.UNIT_SIZE)
	}
};

export const SORT_TYPES = {
	recommended: {
		label: "Recommended",
		value: "CronutNVF100DonutsLowLTV"
	},
	price: {
		label: "Price",
		value: "price"
	},
	distance: {
		label: "Distance",
		value: "distance"
	},
	reviews: {
		label: "Reviews",
		value: "reviews"
	}
};

export const defaultSort = SORT_TYPES.recommended.value;

export default defaultFilterOptions;
