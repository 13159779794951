import React from "react";
import { hydrate } from "react-dom";
import { loadableReady } from "@loadable/component";
import createStore from "store/create";
import initialize from "utils/app/initialize";
import App from "containers/App";

// Create the store from the state injected via the server-generated HTML

const decodedState = window.__PRELOADED_STATE__ ?
	JSON.parse(atob(window.__PRELOADED_STATE__, "base64")) : null;

const store = createStore(decodedState);

// Initialize segment
initialize(store);

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__;

// remove the `#preloaded-state` element
const preloadedStateElement = document.getElementById("preloadedState");
if (preloadedStateElement) {
	preloadedStateElement.remove();
}

const render = (AppComponent) =>
	hydrate(<AppComponent store={store} />, document.getElementById("app"));

loadableReady(() => render(App));

if (module.hot) {
	module.hot.accept(["./containers/App"], () => {
		const NextApp =
			require("./containers/App").default; /* eslint-disable-line global-require, max-len */
		render(NextApp);
	});
}
