import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { pick, get } from "lodash";
import searchSchema from "store/schema/search";
import { PageTypes } from "@sparefoot/segment-react";
import { createAPIAction } from "store/actions/api";
import defaultFilterOptions, { defaultSort } from "config/filters";
import navigate from "utils/navigate";
import { buildSearchFilterLink } from "utils/search/buildSearchLink";
import buildLandingQuery from "utils/search/buildLandingQuery";

// =============================================================================
// INITIAL STATE
// =============================================================================

export const initialState = {
	loading: false,
	locationKey: null,
	error: null,
	statusCode: null,
	facetCounts: {},
	facilities: [],
	listings: null,
	nearbyFacilities: [],
	searchFilters: defaultFilterOptions,
	sort: defaultSort,
	cityUrl: null,
	stateUrl: null,
	query: {},
	content: {},
	meta: {},
	location: {},
	pageType: null
};

// =============================================================================
// REDUCER
// =============================================================================

const reducers = {
	activateSearchFilterItem: (state, action) => {
		const { payload } = action;
		const { name, value } = payload;
		// e.g. searchFilters.amenities.options.climateControlled
		// only try to check if the value exists
		if (state.searchFilters[name].options[value]) {
			state.searchFilters[name].options[value].checked = true;
		}
	},
	deactivateSearchFilterItem: (state, { payload }) => {
		const { name, value } = payload;
		// e.g. searchFilters.amenities.options.climateControlled
		if (state.searchFilters[name].options[value]) {
			state.searchFilters[name].options[value].checked = false;
		}
	},
	soloSearchFilterItem: (state, { payload }) => {
		// activates the provided option, deactivates all others

		// taken from the "solo" functionality in a DAW,
		// to only listen to one track and mute all the others
		const { name, value } = payload;
		const { options } = state.searchFilters[name];
		const updatedOptions = Object.keys(options).reduce((acc, key) => {
			acc[key] = {
				...options[key],
				checked: key === value
			};
			return acc;
		}, {});

		state.searchFilters[name].options = {
			...updatedOptions
		};
	},
	setSortOrder: (state, { payload }) => {
		state.sort = payload;
	}
};

// =============================================================================
// THUNKS
// =============================================================================

// TODO: check location key, ensures they're searching a new location?
// Results and search page meta (e.g. SEO data)
export const getSearchPage = createAPIAction({
	actionName: "search/getSearchPage",
	uri: "/api/search",
	schema: searchSchema,
	queryDefaults: {
		sort: defaultSort,
		excludeOon: true
	}
});

export const getLandingPage = (match, queryParams, landingType) => {
	const query = buildLandingQuery(match, queryParams, landingType);
	return createAPIAction({
		actionName: "search/getSearchPage",
		uri: "/api/search/landing",
		schema: searchSchema,
		queryDefaults: {
			sort: defaultSort,
			excludeOon: true
		}
	})({ query });
};

// A page of facilities (e.g. without SEO data)
export const getSearchResults = createAPIAction({
	actionName: "search/getSearchResults",
	uri: "/api/search/slim",
	schema: searchSchema,
	queryDefaults: {
		sort: defaultSort,
		excludeOon: true
	}
});

// =============================================================================
// EXTRA REDUCERS
// =============================================================================

const extraReducers = (builder) => {
	builder
		.addMatcher(
			isAnyOf(getSearchPage.pending, getSearchResults.pending),
			(state) => {
				// const locationKey = get(action, 'meta.locationKey');

				// state.locationKey = locationKey;
				// state.loading = locationKey !== state.locationKey;
				state.loading = true;
				// state.pageType = null;
				// state.nearbyFacilities = [];
			}
		)
		.addMatcher(isAnyOf(getSearchPage.fulfilled), (state, action) => {
			const { entities, result } = action.payload;
			const search = entities.search[result];

			// Handles rerouting to other cities, states
			// const alternateCity = get(search, 'content.lowPerformingAlt.alternateCity');
			// const alternateState = get(search, 'content.lowPerformingAlt.alternateState');
			// if (alternateCity && alternateState) {
			// 	return redirectState(state, `/self-storage/${alternateState}/${alternateCity}/`);
			// }

			// the query as returned by the API, not the params
			state.query = search.query;

			// 1:1
			state.searchType = search.searchType;
			state.facetCounts = search.facetCounts;
			state.cityUrl = search.cityUrl;
			state.stateUrl = search.stateUrl;
			state.location = {
				...search.location,
				preferredPlaceName:
					search.location?.subLocality || search.location?.city
			};
			state.locationHash = search.locationHash;
			state.meta = get(search, "meta", {});
			state.pageText = get(search.content, "pageText", null);
			state.nearbyCities = get(search.content, "nearbyCities", null);
			state.amenityPages = get(
				search.content,
				"cityLinks.amenities",
				null
			);
			state.headline = search.content?.meta?.headline;
			state.staticMapUrl = search.staticMapUrl;
			state.totalPages = search.totalPages;
			state.totalListings = search.totalListings;
			state.listingsPerPage = search.listingsPerPage;
			state.lastInOriginalSearch = search.lastInOriginalSearch;

			// remaps
			state.pageType = search.query?.pageType || PageTypes.SEARCH;
			state.subPageType = search.query?.subpageType || state.pageType;
			// state.filtersShowing = false;
			state.content = search.content; // old version reverts to state.content if not SEARCH_SUCCESS
			// state.vipAccounts = VIP_ACCOUNTS
		})
		.addMatcher(isAnyOf(getSearchPage.fulfilled), (state, action) => {
			// Initialize the search filter state based on the query
			const { entities, result } = action.payload;
			const search = entities.search[result];

			Object.entries(
				pick(search.query, Object.keys(state.searchFilters))
			).forEach(([filterGroup, activeFilters]) => {
				[activeFilters].flat().forEach((filter) => {
					state.searchFilters[filterGroup].options[
						filter
					].checked = true;
				});
			});
			// initialize sort state
			state.sort = get(search.query, "sort", defaultSort);
		})
		.addMatcher(
			isAnyOf(getSearchPage.fulfilled, getSearchResults.fulfilled),
			(state, action) => {
				const { entities, result } = action.payload;
				if (result) {
					const search = entities.search[result];
					state.searchId = search.searchId;
					state.facilities = [
						...state.facilities,
						...search.listings
					];
					state.page = search.page;
					state.hasMorePages = search.page !== search.totalPages;
				}
				// state
				state.error = null;
				state.loading = false;
			}
		)
		.addMatcher(
			isAnyOf(getSearchPage.rejected, getSearchResults.rejected),
			(state) => {
				// handle redirecting to error page
				state.error = true;
				state.loading = false;
			}
		);
};

// =============================================================================
// SLICE
// =============================================================================

export const searchSlice = createSlice({
	name: "search",
	initialState,
	reducers,
	extraReducers
});

// =============================================================================
// SELECTORS
// =============================================================================

export const selectCheckedOptionsByGroup = (state, groupName) => {
	const options = Object.values(state.search.searchFilters[groupName].options)
		// we can filter through just those values for whichever is checked
		.filter((option) => option.checked)
		// and just return a string of the option value, e.g. 'climateControlled'
		.map(({ value }) => value);

	const allowMultiple = state.search.searchFilters[groupName].multiple;
	// if this is a 'multiple' group, we need to return an array
	// otherwise, there should only be one element, so we return a string
	return allowMultiple ? options : options.join();
};

export const selectSearchFilterParams = (state) =>
	Object
		// Returns an object we can encode in a url of just the checked options

		// get the filter group keys as an array
		.keys(state.search.searchFilters)
		// reduce that array to an object with each filter group key as a key,
		// and the value as an array of the checked options from that group
		// e.g. {'amenties' :['climateControlled']}
		.reduce(
			(acc, cur) => ({
				...acc,
				// options is an object with the options' values as keys
				// e.g. {'climateControlled: {'checked': false, 'value': 'climateControlled'}}
				[cur]: selectCheckedOptionsByGroup(state, cur)
			}),
			{}
		);

export const {
	soloSearchFilterItem,
	activateSearchFilterItem,
	deactivateSearchFilterItem,
	setSortOrder,
	setNextPage
} = searchSlice.actions;

export const selectSortParam = (state) => ({
	sort: state.search.sort
});

export const applySearch = (pathname, queryParams) => (dispatch, getState) => {
	const filterParams = selectSearchFilterParams(getState());
	const sortParam = selectSortParam(getState());
	const newUrl = buildSearchFilterLink(pathname, {
		...queryParams,
		...sortParam,
		...filterParams
	});
	navigate(`${newUrl}#searchPage`);
};

export const toggleOnSearchFilter =
	({ name, value }) =>
	(dispatch, getState) => {
		// Checks to see if filter group allows mutliple, then either activates or solos the item
		const { multiple } = getState().search.searchFilters[name];
		if (!multiple) dispatch(soloSearchFilterItem({ name, value }));
		else dispatch(activateSearchFilterItem({ name, value }));
	};

export const getNextSearchResultsPage = (queryParams) =>
	function loadNextFacilities(dispatch, getState) {
		const filterParams = selectSearchFilterParams(getState());
		const sortParams = selectSortParam(getState());
		const nextPageQueryParams = {
			...queryParams,
			page: queryParams.page + 1
		};
		dispatch(
			getSearchResults({
				query: {
					...nextPageQueryParams,
					...sortParams,
					...filterParams
				}
			})
		);
	};

export default searchSlice.reducer;
