import React from "react";
import WineSVG from "assets/icons/wine.inline.svg";

import Icon from "./Icon";

const Wine = ({ ...rest }) => (
	<Icon
		name="wine-storage"
		alt="Glass of wine"
		{...rest}
	>
		<WineSVG />
	</Icon>
);

export default Wine;
