import React from "react";
import UnitSVG from "assets/icons/unit.inline.svg";

import Icon from "./Icon";

const StorageUnit = ({ ...rest }) => (
	<Icon
		name="storage-unit"
		alt="A dolly carrying a box"
		{...rest}
	>
		<UnitSVG />
	</Icon>
);

export default StorageUnit;
