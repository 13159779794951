import React from "react";
import { Link } from "components/core/Link";
import states from "config/states";

import "./StateLinks.scss";

export default function StateLinks() {
	return (
		<ul className="states-list">
			{states.map(({ abbv, url }) => (
				<li
					className="state"
					key={abbv}
				>
					<Link
						last
						weight="semiBold"
						color="lightBlue"
						className="state-link"
						segmentLabel={`state ${abbv}`}
						href={url}
					>
						{abbv}
					</Link>
				</li>
			))}
		</ul>
	);
}
