import { get, pick } from "lodash";
import { schema } from "normalizr";
import transformLocation from "utils/location";
import reviewSchema from "./review";
import UnitSchema from "./unit";

const FACILITY_CARD_KEY_ALLOWLIST = ["nearbyFacilities", "listings"];
const isInFacilityCardAllowList = (k) =>
	FACILITY_CARD_KEY_ALLOWLIST.includes(k);

export const findSortId = (v, p, k) => {
	if (!isInFacilityCardAllowList(k)) return null;
	const { page, listingsPerPage } = p;
	const pageOffset = (page - 1) * listingsPerPage;
	const pageRelativeIndex = p[k]?.findIndex((f) => f.id === v.id);
	return pageOffset + pageRelativeIndex;
};

export const buildAddress = (v) => {
	const { location } = v;
	return `${location.streetAddress1}, ${location.city}, ${location.state} ${location.zip}`;
};

const PREFERRED_PROMOTION_ORDER = ["largeUnit", "mediumUnit", "smallUnit"];

export const findUnitPromotions = (facility, key) => {
	/**
	 * The units property on the facility object has up to four
	 * unit size keys, `smallUnit` to `largeUnit` and a
	 * recommended unit.
	 *
	 * To find the promotion we want to share with the schema, we need
	 * to see if these have promotions, and provide the promotion of the
	 * largest unit.
	 *
	 * This only works for search pages where facility entities are
	 * under a 'listings' key, or nearby facilities on facility pages
	 * that are under a 'nearbyFacilities' key.
	 *
	 * */

	if (!isInFacilityCardAllowList(key)) return null;

	const NO_PROMOTION = "";
	const { units } = facility;

	// we omit the 'recommended' unit and only pick the sized ones
	const facilityUnits = pick(units, PREFERRED_PROMOTION_ORDER);
	// Picks out the promotion of the largest unit
	const preferredPromotion = PREFERRED_PROMOTION_ORDER.reduce(
		(promotion, unitSize) => {
			// if there's a value in the acc, return it
			// since we assume it looked at 'large' first
			if (promotion) return promotion;
			// otherwise, if the unit has a special, return that special
			return get(facilityUnits[unitSize], "special", NO_PROMOTION);
		},
		NO_PROMOTION
	);

	return preferredPromotion;
};
const PRICING_UNIT_ORDER = [
	"smallUnit",
	"mediumUnit",
	"largeUnit",
	"recommendedUnit"
];

export const findUnitPricing = (facility, key) => {
	/**
	 * For facility cards, we base our starting and lowest prices on
	 * the smallest unit available.
	 *
	 * This only works for search pages where facility entities are
	 * under a 'listings' key, or nearby facilities on facility pages
	 * that are under a 'nearbyFacilities' key.
	 */

	if (!isInFacilityCardAllowList(key)) return {};

	const unitPricing = { startingPrice: null, lowestPrice: null };

	const { units } = facility;

	const pricingUnit = PRICING_UNIT_ORDER.reduce((unit, unitSize) => {
		if (unit) return unit;
		return get(units, `${unitSize}`, "");
	}, "");

	unitPricing.lowestPrice = get(pricingUnit, "price.sparefoot", null);
	unitPricing.startingPrice = get(pricingUnit, "price.regular", null);

	return unitPricing;
};

// key is the key in the response the entity is at
// value is the entity itself, unnormalized
// parent is the normalized response

const processStrategy = (value, parent, key) => ({
	...value,
	location: transformLocation(value),
	address: buildAddress(value),
	url: value.url,
	sortId: findSortId(value, parent, key),
	unitPromotion: findUnitPromotions(value, key),
	...findUnitPricing(value, key)
});

const facilitySchema = new schema.Entity(
	"facilities",
	{
		units: {
			storageUnits: [UnitSchema],
			vehicleUnits: [UnitSchema],
			smallUnit: UnitSchema,
			mediumUnit: UnitSchema,
			largeUnit: UnitSchema,
			recommendedUnit: UnitSchema,
			enclosed: UnitSchema,
			outdoorCovered: UnitSchema,
			outdoorUncovered: UnitSchema,
			indoor: UnitSchema
		},
		userReviews: [reviewSchema],
		bestReviews: [reviewSchema]
	},
	{
		processStrategy
	}
);

facilitySchema.define({
	nearbyFacilities: [facilitySchema]
});

export default facilitySchema;
