export const storage101 = {
	menuName: "Storage 101",
	links: [
		{
			text: "Size Guide",
			href: "/self-storage-size-guide/"
		},
		{
			text: "Indoor vs. Outdoor Storage",
			href: "/indoor-vs-outdoor/"
		},
		{
			text: "Storage Security",
			href: "/storage-security/"
		},
		{
			text: "Storage Insurance",
			href: "/storage-insurance/"
		}
	]
};

export const storageTypes = {
	menuName: "Storage Types",
	links: [
		{
			text: "Size Guide",
			href: "/self-storage-size-guide/"
		},
		{
			text: "Self Storage",
			href: "/storage-units-near-me/"
		},
		{
			text: "Vehicle Storage",
			submenu: [
				{
					text: "Car Storage",
					href: "/car-storage/"
				},
				{
					text: "RV Storage",
					href: "/rv-storage/"
				},
				{
					text: "Boat Storage",
					href: "/boat-storage/"
				},
				{
					text: "Motorcycle Storage",
					href: "/motorcycle-storage/"
				}
			]
		},
		{
			text: "Climate Controlled Storage",
			href: "/climate-controlled/"
		},
		{
			text: "College Storage",
			href: "/college-student-storage/"
		},
		{
			text: "Business Storage",
			href: "/business-storage/"
		}
	]
};
