import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Link as TypographyLink } from "components/core/Typography";

import "./Link.scss";

const Link = ({
	onClick,
	segmentLabel,
	segmentProperties,
	children,
	icon,
	reverse,
	className,
	hover,
	underline,
	target,
	...rest
}) => {


	const props = {
		onClick,
		target,
		className: classnames(
			"sf-link",
			{
				icon,
				reverse,
				hover,
				underline
			},
			className
		),
		...rest
	};

	return (
		<TypographyLink {...props}>
			{icon ? (
				<>
					{icon}
					<span>{children}</span>
				</>
			) : (
				children
			)}
		</TypographyLink>
	);
};

Link.defaultProps = {
	color: "blue",
	reverse: false,
	hover: false,
	underline: false,
	segmentLabel: null,
	segmentProperties: {},
	className: null,
	onClick: () => { },
	target:"_self",
};

Link.propTypes = {
	color: TypographyLink.propTypes.color,
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
	segmentLabel: PropTypes.string,
	segmentProperties: PropTypes.object,
	reverse: PropTypes.bool,
	onClick: PropTypes.func,
	hover: PropTypes.bool,
	underline: PropTypes.bool,
	icon: PropTypes.node,
	target: PropTypes.string,
};

export default Link;
