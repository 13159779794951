import { configureStore } from "@reduxjs/toolkit";
import app from "store/modules/app";
import user from "store/modules/user";
import search from "store/modules/search";
import facilities from "store/modules/facilities";
import bookings from "store/modules/booking";
import stateData from "store/modules/state";
import units from "store/modules/units";
import reviews from "store/modules/reviews";
import phone from "store/modules/phone"
import experiments from "@sparefoot/react-commons/modules/experiments";
import middleware from "./middleware";

export const INITIALIZE = "STORAGE/INITIALIZE";

export function initialize() {
	return {
		type: INITIALIZE
	};
}

export default function createStore(preloadedState) {
	return configureStore({
		preloadedState,
		reducer: {
			app,
			user,
			experiments,
			search,
			facilities,
			units,
			reviews,
			bookings,
			stateData,
            phone,
		},
		middleware: (getDefaultMiddleware) => [
			// combine our custom middleware with the default
			...getDefaultMiddleware(),
			...middleware
		]
	});
}
