import { createEntityAdapter, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { get } from "lodash";
import mergeEntities from "utils/store/mergeEntities";
import { createAPIAction } from "store/actions/api";
import phoneSchema from "store/schema/phone";

// =============================================================================
// INITIAL STATE
// =============================================================================

const phoneAdapter = createEntityAdapter({
	selectId: (phone) => phone.number
});

export const initialState = phoneAdapter.getInitialState({
	loading: false,
	error: null,
	statusCode: null,
	tollFreeNumber: {},
	localNumber: {},
	byId: {}
});

const ERROR_NUMBER = {
	result: {
		number: "8772088215",
		formattedNumber: "1 (877) 208-8215"
	}
};

// =============================================================================
// REDUCER
// =============================================================================

const reducers = {};

// =============================================================================
// THUNKS
// =============================================================================

export const getTollfree = createAPIAction({
	actionName: `phone/getTollfree`,
	uri: `/api/phone/tollfree/`,
	schema: phoneSchema
});

export const getLocalNumber = createAPIAction({
	actionName: `phone/getLocalNumber`,
	uri: `/api/phone/local/`,
	schema: phoneSchema
});

// =============================================================================
// EXTRA REDUCERS
// =============================================================================

const extraReducers = (builder) => {
	builder
		.addMatcher(isAnyOf(getTollfree.pending), (state) => {
			state.loading = true;
		})
		.addMatcher(isAnyOf(getTollfree.fulfilled), (state, action) => {
			const { result, meta } = action.payload;
			// Clear loading and errors
			state.loading = false;
			state.error = false;

			// Store the booking details
			const tollFreeNumber = get(action, "payload.entities.phone", {});
			state.byId = mergeEntities(state.byId, tollFreeNumber);

			// Store the info about the current phone
			state.tollFreeNumber = {
				result,
				...meta
			};
		})
		.addMatcher(isAnyOf(getTollfree.rejected), (state, action) => {
			state.loading = false;
			state.error = true;
			state.tollFreeNumber = ERROR_NUMBER;
			action.payload = ERROR_NUMBER;
		})

		.addMatcher(isAnyOf(getLocalNumber.pending), (state) => {
			state.loading = true;
		})
		.addMatcher(isAnyOf(getLocalNumber.fulfilled), (state, action) => {
			const { result, meta } = action.payload;
			// Clear loading and errors
			state.loading = false;
			state.error = false;

			// Store the booking details
			const localNumber = get(action, "payload.entities.phone", {});
			state.byId = mergeEntities(state.byId, localNumber);

			// Store the info about the current phone
			state.localNumber = {
				result,
				...meta
			};
		})
		.addMatcher(isAnyOf(getLocalNumber.rejected), (state, action) => {
			state.loading = false;
			state.error = true;
			state.localNumber = ERROR_NUMBER;
			action.payload = ERROR_NUMBER;
		});
};

// =============================================================================
// SLICE
// =============================================================================

export const phoneSlice = createSlice({
	name: "phone",
	initialState,
	reducers,
	extraReducers
});

export default phoneSlice.reducer;
