import React from "react";
import ExclamationSVG from "assets/icons/exclamation.inline.svg";

import Icon from "./Icon";

const Exclamation = ({ ...rest }) => (
	<Icon
		name="exclamation"
		alt="exclamation"
		{...rest}
	>
		<ExclamationSVG />
	</Icon>
);

export default Exclamation;