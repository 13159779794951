import React from "react";
import CalendarSVG from "assets/icons/calendar.inline.svg";
import Icon from "./Icon";

const Calendar = ({ ...rest }) => (
	<Icon
		name="calendar"
		alt="Calendar"
		{...rest}
	>
		<CalendarSVG />
	</Icon>
);
export default Calendar;
