import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { get } from "lodash";
import mergeEntities from "utils/store/mergeEntities";
import { getSearchPage, getSearchResults } from "store/modules/search";
import { getFacilityPage } from "store/modules/facilities";

// =============================================================================
// INITIAL STATE
// =============================================================================

export const initialState = {
	loading: false,
	error: null,
	statusCode: null,
	byId: {}
};

// =============================================================================
// REDUCER
// =============================================================================

const reducers = {};

// =============================================================================
// THUNKS
// =============================================================================

// =============================================================================
// EXTRA REDUCERS
// =============================================================================

const extraReducers = (builder) => {
	builder.addMatcher(
		isAnyOf(
			getSearchPage.fulfilled,
			getSearchResults.fulfilled,
			getFacilityPage.fulfilled
		),
		(state, action) => {
			const units = get(action, "payload.entities.units") || {};
			state.byId = mergeEntities(state.byId, units);
		}
	);
};

// =============================================================================
// SLICE
// =============================================================================

export const unitsSlice = createSlice({
	name: "units",
	initialState,
	reducers,
	extraReducers
});

// =============================================================================
// SELECTORS
// =============================================================================

export const selectUnitById = (state, id) => state.units.byId[id];
export const selectUnitsByIds = (state, ids) =>
	ids.map((id) => selectUnitById(state, id));

export default unitsSlice.reducer;
