import { logExperimentBucketing } from "@sparefoot/react-commons/modules/experiments";

const readyMiddleware = (store) => (next) => (action) => {
	/**
	 * This is middleware for doing things once the app has initialized.
	 * A useEffect in ApplicationWrapper calls the setAppReady action.
	 */
	if (action.type === "app/setAppReady") {
		store.dispatch(logExperimentBucketing());
	}
	return next(action);
};

export default readyMiddleware;
