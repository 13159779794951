import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

Bugsnag.start({
	apiKey: process.env.BUGSNAG_TOKEN,
	plugins: [new BugsnagPluginReact()],
	releaseStage: `SDC - ${  process.env.NODE_ENV}`,
	onError(event) {
		event.addMetadata("Site - SDC", {
			url: window.location.href
		});
	}
});

export const ErrorBoundary =
	Bugsnag.getPlugin("react").createErrorBoundary(React);
export default Bugsnag;
