/* eslint-disable max-len */
import loadable from "@loadable/component";

export const HomePage = loadable(() =>
	import(/* webpackChunkName: "HomePage" */ "./HomePage/HomePage")
);
export const SearchPage = loadable(() =>
	import(/* webpackChunkName: "SearchPage" */ "./SearchPage/SearchPage")
);
export const ErrorPage = loadable(() =>
	import(/* webpackChunkName: "ErrorPage" */ "./ErrorPage/ErrorPage")
);
export const StyleGuide = loadable(() =>
	import(/* webpackChunkName: "StyleGuide" */ "./StyleGuide/StyleGuide")
);
export const FacilityPage = loadable(() =>
	import(/* webpackChunkName: "FacilityPage" */ "./FacilityPage/FacilityPage")
);
export const ConfirmationPage = loadable(() =>
	import(
		/* webpackChunkName: "ConfirmationPage" */ "./ConfirmationPage/ConfirmationPage"
	)
);
export const RVStoragePage = loadable(() =>
	import(
		/* webpackChunkName: "RVStoragePage" */ "./StorageTypesPages/RVStoragePage/RVStoragePage"
	)
);
export const SelfStoragePage = loadable(() =>
	import(
		/* webpackChunkName: "SelfStoragePage" */ "./StorageTypesPages/SelfStoragePage/SelfStoragePage"
	)
);
export const CheapStoragePage = loadable(() =>
	import(
		/* webpackChunkName: "CheapStoragePage" */ "./StorageTypesPages/CheapStoragePage/CheapStoragePage"
	)
);
export const MilitaryStoragePage = loadable(() =>
	import(
		/* webpackChunkName: "MilitaryStoragePage" */ "./StorageTypesPages/MilitaryStoragePage/MilitaryStoragePage"
	)
);
export const StudentStoragePage = loadable(() =>
	import(
		/* webpackChunkName: "StudentStoragePage" */ "./StorageTypesPages/StudentStoragePage/StudentStoragePage"
	)
);
export const BusinessStoragePage = loadable(() =>
	import(
		/* webpackChunkName: "BusinessStoragePage" */ "./StorageTypesPages/BusinessStoragePage/BusinessStoragePage"
	)
);
export const BoatStoragePage = loadable(() =>
	import(
		/* webpackChunkName: "BoatStoragePage" */ "./StorageTypesPages/BoatStoragePage/BoatStoragePage"
	)
);
export const CarStoragePage = loadable(() =>
	import(
		/* webpackChunkName: "CarStoragePage" */ "./StorageTypesPages/CarStoragePage/CarStoragePage"
	)
);
export const ClimateControlledStoragePage = loadable(() =>
	import(
		/* webpackChunkName: "ClimateControlledStoragePage" */ "./StorageTypesPages/ClimateControlledStoragePage/ClimateControlledStoragePage"
	)
);
export const StateLandingPage = loadable(() =>
	import(
		/* webpackChunkName: "StateLandingPage" */ "./StateLandingPage/StateLandingPage"
	)
);
export const HelpPage = loadable(() =>
	import(
		/* webpackChunkName: "HelpPage" */ "./StorageTypesPages/HelpPage/HelpPage"
	)
);
export const TermsAndConditionsPage = loadable(() =>
	import(
		/* webpackChunkName: "TermsAndConditionsPage" */ "./SDCPages/TermsAndConditionsPage/TermsAndConditionsPage"
	)
);
export const PaidLandingPage = loadable(() =>
	import(
		/* webpackChunkName: "PaidLandingPage" */ "./SDCPages/PaidLandingPage/PaidLandingPage"
	)
);
export const SizeGuidePage = loadable(() =>
	import(
		/* webpackChunkName: "SizeGuidePage" */ "./StorageTypesPages/SizeGuidePage/SizeGuidePage"
	)
);
export const PrivacyPolicyPage = loadable(() =>
	import(
		/* webpackChunkName: "PrivacyPolicyPage" */ "./SDCPages/PrivacyPolicyPage/PrivacyPolicyPage"
	)
);
export const ContactUsPage = loadable(() =>
	import(
		/* webpackChunkName: "ContactUsPage" */ "./SDCPages/ContactUsPage/ContactUsPage"
	)
);
