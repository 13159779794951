import React from "react";
import { Link } from "components/core/Link";

import "./TopCities.scss";

const topCities = [
	{ text: "Austin", url: "https://www.storage.com/self-storage/texas/austin/" },
	{ text: "Atlanta", url: "https://www.storage.com/self-storage/georgia/atlanta/" },
	{ text: "Bronx", url: "https://www.storage.com/self-storage/new-york/bronx/" },
	{ text: "Brooklyn", url: "https://www.storage.com/self-storage/new-york/brooklyn/" },
	{ text: "Chicago", url: "https://www.storage.com/self-storage/illinois/chicago/" },
	{ text: "Columbus", url: "https://www.storage.com/self-storage/ohio/columbus/" },
	{ text: "Dallas", url: "https://www.storage.com/self-storage/texas/dallas/" },
	{ text: "Denver", url: "https://www.storage.com/self-storage/colorado/denver/" },
	{ text: "Houston", url: "https://www.storage.com/self-storage/texas/houston/" },
	{ text: "Jacksonville", url: "https://www.storage.com/self-storage/florida/jacksonville/" },
	{ text: "Las Vegas", url: "https://www.storage.com/self-storage/nevada/las-vegas/" },
	{ text: "Los Angeles", url: "https://www.storage.com/self-storage/california/los-angeles/" },
	{ text: "Miami", url: "https://www.storage.com/self-storage/florida/miami/" },
	{ text: "Orlando", url: "https://www.storage.com/self-storage/florida/orlando/" },
	{ text: "Philadelphia", url: "https://www.storage.com/self-storage/pennsylvania/philadelphia/" },
	{ text: "Phoenix", url: "https://www.storage.com/self-storage/arizona/phoenix/" },
	{ text: "Queens", url: "https://www.storage.com/self-storage/new-york/queens/" },
	{ text: "San Antonio", url: "https://www.storage.com/self-storage/texas/san-antonio/" },
	{ text: "San Diego", url: "https://www.storage.com/self-storage/california/san-diego/" },
	{ text: "San Francisco", url: "https://www.storage.com/self-storage/california/san-francisco/" },
	{ text: "San Jose", url: "https://www.storage.com/self-storage/california/san-jose/" },
	{ text: "Tampa", url: "https://www.storage.com/self-storage/florida/tampa/" },
	{
		text: "Washington, D.C.",
		url: "https://www.storage.com/self-storage/district-of-columbia/washington/"
	}
];

export default function TopCities() {
	return (
		<div className="top-cities">
			<ul className="list">
				{topCities.map(({ url, text }) => (
					<li
						className="list-item"
						key={text}
					>
						<Link
							underline
							hover
							nowrap
							color="white"
							key={text}
							href={url}
							segmentLabel={text}
						>
							{text}
						</Link>
					</li>
				))}
			</ul>
		</div>
	);
}
