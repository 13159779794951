import React from "react";
import CircleArrowSVG from "assets/icons/circle-arrow.inline.svg";

import Icon from "./Icon";

const CircleArrow = ({ ...rest }) => (
	<Icon
		name="circle-arrow"
		alt="circle-arrow"
		{...rest}
	>
		<CircleArrowSVG />
	</Icon>
);

export default CircleArrow;
