import React from "react";
import CrossSVG from "assets/icons/cross.inline.svg";

import Icon from "./Icon";

const Cross = ({ ...rest }) => (
	<Icon
		name="close"
		alt="Close"
		{...rest}
	>
		<CrossSVG />
	</Icon>
);

export default Cross;
