import React from "react";
import IdSVG from "assets/icons/id.inline.svg";

import Icon from "./Icon";

const Id = ({ ...rest }) => (
	<Icon
		name="id"
		alt="id"
		{...rest}
	>
		<IdSVG />
	</Icon>
);

export default Id;
