import React from "react";
import OutdoorSpaceSVG from "assets/icons/outdoor-space.inline.svg";

import Icon from "./Icon";

const OutdoorSpace = ({ ...rest }) => (
	<Icon
		name="outdoor-space"
		alt="A sun and moon"
		{...rest}
	>
		<OutdoorSpaceSVG />
	</Icon>
);

export default OutdoorSpace;
