import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import ShowNumber from "./ShowNumber/ShowNumber";
import "./ClickToCall.scss";

const ClickToCall = ({
	fetched,
	className,
	phone,
	handleFetchNumber,
	showIcon,
	underline,
	color
}) => {
	const hasPhoneNumber = phone?.number || fetched;

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
		<div
			className={classnames(
				"click-to-call",
				{ "show-number": hasPhoneNumber },
				`color-${color}`,
				{ underline },
				className
			)}
			onClick={(e) => e.stopPropagation()}
		>
			<ShowNumber
				color={color}
				underline={underline}
				showIcon={showIcon}
				phone={phone}
				handleFetchNumber={handleFetchNumber}
			/>
		</div>
	);
};

ClickToCall.propTypes = {
	showIcon: PropTypes.bool,
	handleFetchNumber: PropTypes.func,
	className: PropTypes.string,
	fetched: PropTypes.bool,
	underline: PropTypes.bool,
	color: PropTypes.oneOf(["blue", "white"]),
	phone: PropTypes.shape({
		formattedNumber: PropTypes.string,
		number: PropTypes.string
	})
};

ClickToCall.defaultProps = {
	showIcon: true,
	underline: false,
	color: "blue",
	fetched: false
};

export default ClickToCall;
