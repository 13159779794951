import { encodeQueryStringValue } from "utils/search/buildSearchLink";

import { isEmpty } from "lodash";
import { normalize } from "normalizr";
import RequestError from "utils/error/request";

export default function callAPI({
	url,
	options = {
		query: {},
		body: {},
		headers: {},
		method: "GET"
	},
	schema
}) {
	const queryString = !isEmpty(options.query)
		? `?${encodeQueryStringValue(options.query)}`
		: "";

	const requestOptions = {
		headers: options.headers || {},
		method: options.method || "GET",
		body: options.body ? JSON.stringify(options.body) : undefined
	};

	return fetch(`${url}${queryString}`, requestOptions)
		.then(async (res) => {
			try {
				return res.json()
			} catch (error) {
				console.log(`Request made to API (${url}${queryString}) is not valid JSON.\n\n\n Response:\n${res.text()}`);
				throw error;
			}
		})
		.then((data) => {
			if (schema) {
				return { ...normalize(data, schema), meta: data.meta || {} };
			}
			return data;
		})
		.catch((error) => {
			throw new RequestError(error);
		});
}
