import { head } from "lodash";
import GoogleMapsLoader from "@sparefoot/google-maps";

let googleLoadPromise;
const elements = [];

export function initializeGoogle({ key }) {
	// allow ?MAPS_KEY=foobar to simulate invalid key error
	// eslint-disable-next-line no-unused-vars
	const [_, match, ...rest] =
		window.location.search.match(/[?&]MAPS_KEY=(([^&#]*)|&|#|$)/) || [];
	if (match) {
		key = match;
	}

	GoogleMapsLoader.KEY = key;
	GoogleMapsLoader.LIBRARIES = ["places"];
}

export function loadGoogle() {
	if (typeof window !== "undefined") {
		googleLoadPromise = new Promise((resolve) => {
			GoogleMapsLoader.load((google) => resolve(google));
		});
	}
}

export function removeAutocomplete(autoComplete) {
	return googleLoadPromise && autoComplete
		? googleLoadPromise.then((google) => {
				google.maps.event.clearInstanceListeners(autoComplete);
		  })
		: Promise.resolve();
}

export function clearAutocomplete(autoComplete) {
	removeAutocomplete(autoComplete);
	autoComplete.removeAttribute("placeholder"); //	oops something went wrong
	autoComplete.removeAttribute("disabled"); //	user can type
	autoComplete.removeAttribute("style"); //	little warning icon
}

export function createAutocomplete(element, onPlaceChange) {
	// Load Google if not already loaded
	if (!googleLoadPromise) {
		loadGoogle();
	}

	elements.push(element);

	window.gm_authFailure = () => {
		elements.forEach((theElement) => {
			clearAutocomplete(theElement);
		});
	};

	return googleLoadPromise.then((theGoogs) => {
		const options = {
			types: ["geocode"],
			componentRestrictions: {
				country: "us"
			},
			fields: [
				"place_id",
				"formatted_address",
				"address_components",
				"geometry"
			]
		};
		const autocomplete = new theGoogs.maps.places.Autocomplete(
			element,
			options
		);

		theGoogs.maps.event.addDomListener(element, "keydown", (event) => {
			// hitting enter to accept an auto complete answer will also trigger
			// form submission before the callback can update state.
			// for that reason, we look to see if the drawer is open, and
			// if enter is pressed (keycode 13) too, we prevent submitting the form.
			// the next time enter is pressed to submit the form the drawer is closed.

			const autoCompleteDrawerOpen = document.querySelectorAll(
				".pac-container .pac-item"
			).length;
			if (event.keyCode === 13 && autoCompleteDrawerOpen) {
				event.preventDefault();
			}
		});

		theGoogs.maps.event.addListener(autocomplete, "place_changed", () => {
			const place = autocomplete.getPlace();
			// place_changed is triggered when user presses enter (even though autocomplete wasn't used)
			// To prevent this from creating a change event, we can check for a place_id which won't exist
			// if enter was pressed.
			if (typeof place.place_id !== "undefined") {
				onPlaceChange(place.formatted_address, place);
			}
		});

		return autocomplete;
	});
}

export function formatPlaceObject(place) {
	const params = {};

	if (place) {
		const addressComponents = place.address_components.reduce(
			(components, c) => ({
				...components,
				[head(c.types)]: c.short_name
			}),
			{}
		);

		params.city = addressComponents.locality;
		params.zip = addressComponents.postal_code;
		params.state = addressComponents.administrative_area_level_1;
		params.locationSource = "google_autocomplete";

		if (place.geometry) {
			params.latitude = place.geometry.location.lat();
			params.longitude = place.geometry.location.lng();
		}
	}
	return params;
}
