import React from "react";
import CircleSlashSVG from "assets/icons/circle-slash.inline.svg";

import Icon from "./Icon";

const CircleSlash = ({ ...rest }) => (
	<Icon
		name="circle-slash"
		alt="circle-slash"
		{...rest}
	>
		<CircleSlashSVG />
	</Icon>
);

export default CircleSlash;
