import React from "react";
import WarningSVG from "assets/icons/warning.inline.svg";
import Icon from "./Icon";

const WarningIcon = ({ ...rest }) => (
	<Icon
		name="warning icon"
		alt="warning-icon"
		{...rest}
	>
		<WarningSVG />
	</Icon>
);

export default WarningIcon;
