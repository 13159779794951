import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./Column.scss";

export default function Column({
	children,
	className,
	small,
	medium,
	mediumLarge,
	large,
	xlarge
}) {
	return (
		<div
			className={classnames(
				"page-column",
				className,
				!!small && `sm-${small}`,
				!!medium && `md-${medium}`,
				!!mediumLarge && `ml-${mediumLarge}`,
				!!large && `lg-${large}`,
				!!xlarge && `xlg-${xlarge}`
			)}
		>
			{children}
		</div>
	);
}

Column.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	small: PropTypes.number,
	medium: PropTypes.oneOf([...new Array(12)].map((_, i) => i + 1)),
	mediumLarge: PropTypes.oneOf([...new Array(12)].map((_, i) => i + 1)),
	large: PropTypes.oneOf([...new Array(12)].map((_, i) => i + 1)),
	xlarge: PropTypes.oneOf([...new Array(12)].map((_, i) => i + 1))
};
