import React from "react";
import ClockSVG from "assets/icons/clock.inline.svg";

import Icon from "./Icon";

const Clock = ({ ...rest }) => (
	<Icon
		name="clock"
		alt="Clock"
		{...rest}
	>
		<ClockSVG />
	</Icon>
);

export default Clock;
