import React from "react";
import PriceTagSVG from "assets/icons/price-tag.inline.svg";
import Icon from "./Icon";

const PriceTag = ({ ...rest }) => (
	<Icon
		name="price-tag"
		alt="Price tag"
		{...rest}
	>
		<PriceTagSVG />
	</Icon>
);

export default PriceTag;
