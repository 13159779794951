import React from "react";
import WorkspaceSVG from "assets/icons/workspace.inline.svg";

import Icon from "./Icon";

const Workspace = ({ ...rest }) => (
	<Icon
		name="workspace"
		alt="A desk with a monitor on it"
		{...rest}
	>
		<WorkspaceSVG />
	</Icon>
);

export default Workspace;
