import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { H3 } from "components/core/Typography/Typography";
import { Link } from "components/core/Link";

import "./LinksColumn.scss";

export default function LinksColumn({ className, title, links }) {
	return (
		<div className={classnames(className, "links-column")}>
			<H3
				className="title"
				size="standard"
				color="white"
			>
				{title}
			</H3>
			<ul className="list">
				{links.map(({ url, text }) => (
					<li
						className="list-item"
						key={text}
					>
						<Link
							last
							hover
							size="small"
							color="white"
							underline
							href={url}
							target={title?.includes("Storable Brands")?"_blank":"_self"}
						>
							{text}
						</Link>
					</li>
				))}
			</ul>
		</div>
	);
}

LinksColumn.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	links: PropTypes.arrayOf(PropTypes.object)
};
