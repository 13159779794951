import React from "react";
import MapPinFilledSVG from "assets/icons/map-pin-filled.inline.svg";
import Icon from "./Icon";

const MapPinFilled = ({ ...rest }) => (
	<Icon
		name="map-pin-filled"
		alt="Map pin"
		{...rest}
	>
		<MapPinFilledSVG />
	</Icon>
);

export default MapPinFilled;
