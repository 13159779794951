import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Column, Row, Container, Group } from "components/core/Page";
import { H3, Paragraph, Text } from "components/core/Typography/Typography";
import { ClickToCall } from "components/brand/ClickToCall";
import TopCities from "components/brand/Footer/TopCities";
import LinksColumn from "components/brand/Footer/LinksColumn";
import StateLinks from "components/brand/Footer/StateLinks";

import Logo from "assets/images/white-logo.svg";
import { getTollfree } from "store/modules/phone";
import { Image } from "@sparefoot/react-commons";
import "./Footer.scss";

const storageByTypeLinks = [
	{ text: "Storage Units Near Me", url: "https://www.storage.com/storage-units-near-me/" },
	{ text: "RV Storage", url: "https://www.storage.com/rv-storage/" },
	{ text: "Climate-controlled", url: "https://www.storage.com/climate-controlled/" },
	{ text: "Boat Storage", url: "https://www.storage.com/boat-storage/" },
	{ text: "Military Storage", url: "https://www.storage.com/military-storage/" },
	{ text: "College Storage", url: "https://www.storage.com/college-student-storage/" },
	{ text: "Car Storage", url: "https://www.storage.com/car-storage/" }
];

const resourcesLinks = [
	{ text: "Contact", url: "https://www.storage.com/help/contact/" },
	{ text: "Size Guide", url: "https://www.storage.com/self-storage-size-guide/" },
	{ text: "Terms", url: "https://www.storage.com/about-us/terms-and-conditions/" },
	{ text: "Privacy Policy", url: "https://www.storage.com/about-us/privacy/" },
	{ text: "Blog", url: "https://blog.storage.com" },
	{
		text: "List Your Facility",
		url: "https://storable.com/products/marketplace"
	},
	{ text: "Site Map", url: "https://www.storage.com/self-storage/" }
];

const brandLinks = [
	{ text: "SpareFoot.com", url: "https://www.sparefoot.com/" },
	{ text: "SelfStorage.com", url: "https://www.selfstorage.com/" },
	{ text: "Storable.com", url: "https://www.storable.com/" },
];

function Footer({ today }) {
	const [phone, setPhone] = useState({});
	const dispatch = useDispatch();
	const handleFetchNumber = async (data) => {
		const {
			payload: { result }
		} = await dispatch(getTollfree(data));
		setPhone(result);
		return result;
	};
	return (
		<div className="footer">
			<Container>
				<Row className="divider">
					<Column
						medium={12}
						large={9}
					>
						<H3
							className="footer-title"
							size="standard"
							color="white"
						>
							Top Cities for Self Storage
						</H3>
						<TopCities />
					</Column>
					<Column
						medium={12}
						large={3}
					>
						<H3
							className="footer-title"
							size="standard"
							color="white"
						>
							States with Self Storage
						</H3>
						<div>
							<StateLinks />
						</div>
					</Column>
				</Row>
				<Row>
					<Column
						medium={12}
						large={5}
					>						
						<Image
							className="logo"
							lazy
							src={Logo}
							alt="storage.com logo"
							width="206"
							height="45"
						/>
						<Paragraph
							className="description"
							color="white"
						>
							Storage.com is a browse-and-compare website for
							renting and reserving self-storage units. With
							thousands of local storage facilities across the
							U.S. listed on our site, we make it easy to find the
							storage unit that best fits your storage needs.
							Whether you’re looking for a storage unit with
							climate control or electricity, or storage for your
							car or RV, Storage.com makes the process quick and
							painless. All you have to do is choose a storage
							unit and your move-in date, and we’ll take care of
							the rest. If you ever have any questions, our
							storage representatives are just a phone call away!
						</Paragraph>
					</Column>
					<Column
						medium={6}
						large={2}
					>
						<LinksColumn
							title="Storage Types"
							links={storageByTypeLinks}
						/>
					</Column>
					<Column
						medium={6}
						large={2}
					>
						<LinksColumn
							title="Resources"
							links={resourcesLinks}
						/>
					</Column>
					<Column
						medium={3}
						large={3}
					>
						<H3
							className="contact-title"
							size="standard"
							color="white"
						>
							Contact
						</H3>
						<ClickToCall
							color="white"
							underline
							handleFetchNumber={handleFetchNumber}
							phone={phone}
						/>
						<Group>
							<LinksColumn
								title="Storable Brands"
								links={brandLinks}
							/>
						</Group>
						<Text
							color="white"
							size="tiny"
						>
							{`Copyright 2011-${today} Storage.com`}
						</Text>
					</Column>
				</Row>
			</Container>
		</div>
	);
}
Footer.propTypes = {
	today: PropTypes.number
};

Footer.defaultProps = {
	today: new Date().getFullYear()
};

export default Footer;
