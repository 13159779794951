import React from "react";
import CaretSVG from "assets/icons/caret.inline.svg";

import Icon from "./Icon";

const Caret = ({ ...rest }) => (
	<Icon
		{...rest}
	>
		<CaretSVG />
	</Icon>
);

export default Caret;
