import React from "react";
import LockerSVG from "assets/icons/locker.inline.svg";

import Icon from "./Icon";

const Lockers = ({ ...rest }) => (
	<Icon
		name="locker"
		alt="A trio of lockers"
		{...rest}
	>
		<LockerSVG />
	</Icon>
);

export default Lockers;
