import React from "react";
import FilterSVG from "assets/icons/filter.inline.svg";

import Icon from "./Icon";

const Filter = ({ ...rest }) => (
	<Icon
		name="filter"
		alt="Filter"
		{...rest}
	>
		<FilterSVG />
	</Icon>
);

export default Filter;
