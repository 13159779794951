import React from "react";
import MapPinOutlineSVG from "assets/icons/map-pin-outline.inline.svg";
import Icon from "./Icon";

const MapPinOutline = ({ ...rest }) => (
	<Icon
		name="map-pin-outline"
		alt="Map pin"
		{...rest}
	>
		<MapPinOutlineSVG />
	</Icon>
);

export default MapPinOutline;
