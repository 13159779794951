import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {
	Button as CoreButton,
	Link as CoreLink
} from "@sparefoot/react-commons";

import "./Button.scss";

function Button({
	theme,
	fullWidth,
	reverse,
	children,
	className,
	disabled,
	type,
	title,
	label,
	href,
	segmentLabel,
	segmentCategory,
	getRef,
	onClick,
	onMouseEnter,
	onMouseLeave
}) {

	const buttonProps = {
		"aria-label": label || title,
		type,
		disabled,
		title: label || title
	};

	const linkProps = {
		href
	};

	let sharedProps = {
		segmentLabel,
		segmentCategory,
		onClick,
		onMouseEnter,
		onMouseLeave,
		ref: getRef,
		className: classnames(
			className,
			"sf-button",
			{
				disabled,
				reverse,
				fullWidth
			},
			!!theme && `theme-${theme}`
		)
	};

	if (href) {
		sharedProps = { ...sharedProps, ...linkProps };
	} else {
		sharedProps = { ...sharedProps, ...buttonProps };
	}

	return React.createElement(
		href ? CoreLink : CoreButton,
		{ ...sharedProps },
		<>{children}</>
	);
}

Button.defaultProps = {
	type: "button",
	theme: "basic",
	href: null,
	fullWidth: true,
	title: null
};

Button.propTypes = {
	fullWidth: PropTypes.bool,
	reverse: PropTypes.bool,
	theme: PropTypes.oneOf([
		"primary",
		"secondary",
		"tertiary",
		"text",
		"icon",
		"basic"
	]),
	children: PropTypes.node,
	className: PropTypes.string,
	href: PropTypes.string,
	type: PropTypes.string,
	getRef: PropTypes.func,
	disabled: PropTypes.bool,
	onClick: PropTypes.func,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	segmentLabel: PropTypes.string,
	segmentCategory: PropTypes.string,
	title: PropTypes.string,
	label: PropTypes.string
};

export default Button;
