/* eslint-disable react/destructuring-assignment */
import React, { Component, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import Routes from "routes";
import { setAppReady, renderError } from "store/modules/app";
import { applicationSelector } from "store/selectors/Application";
import { Header } from "components/brand/Header";
import { Footer } from "components/brand/Footer";
import { TrackingPixel } from "components/core/TrackingPixel";
import { ErrorPage } from "pages/Bundles";

export class ApplicationErrorBoundary extends Component {
	componentDidCatch(error, errorInfo) {
		const { renderError: renderErrorFn, environment } = this.props;
		renderErrorFn(
			environment === "production" ? "" : errorInfo.componentStack
		);
	}

	render() {
		return this.props.children;
	}
}

ApplicationErrorBoundary.propTypes = {
	children: PropTypes.node,
	renderError: PropTypes.func,
	environment: PropTypes.string
};

export function ApplicationWrapper() {
	const { trackingPixelUrl, statusCode, errorMessage, environment } =
		useSelector(applicationSelector);
	const dispatch = useDispatch();
	const dispatchRenderError = (error) => dispatch(renderError(error));

	useEffect(() => {
		// Trigger the appReady action on load.
		dispatch(setAppReady());
	}, []);

	return (
		<ApplicationErrorBoundary
			renderError={dispatchRenderError}
			environment={environment}
		>
			<Header />
			{statusCode === 200 && <Routes />}
			{statusCode !== 200 && (
				<ErrorPage
					statusCode={statusCode}
					message={errorMessage}
				/>
			)}
			<Footer />
			<TrackingPixel src={trackingPixelUrl} />
		</ApplicationErrorBoundary>
	);
}

export default ApplicationWrapper;
