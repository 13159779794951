export default [
	{ abbv: "AL", fullName: "Alabama", url: "https://www.storage.com/self-storage/alabama/" },
	{ abbv: "AK", fullName: "Alaska", url: "https://www.storage.com/self-storage/alaska/" },
	{ abbv: "AZ", fullName: "Arizona", url: "https://www.storage.com/self-storage/arizona/" },
	{ abbv: "AR", fullName: "Arkansas", url: "https://www.storage.com/self-storage/arkansas/" },
	{ abbv: "CA", fullName: "California", url: "https://www.storage.com/self-storage/california/" },
	{ abbv: "CO", fullName: "Colorado", url: "https://www.storage.com/self-storage/colorado/" },
	{ abbv: "CT", fullName: "Connecticut", url: "https://www.storage.com/self-storage/connecticut/" },
	{ abbv: "DE", fullName: "Delaware", url: "https://www.storage.com/self-storage/delaware/" },
	{ abbv: "FL", fullName: "Florida", url: "https://www.storage.com/self-storage/florida/" },
	{ abbv: "GA", fullName: "Georgia", url: "https://www.storage.com/self-storage/georgia/" },
	{ abbv: "HI", fullName: "Hawaii", url: "https://www.storage.com/self-storage/hawaii/" },
	{ abbv: "ID", fullName: "Idaho", url: "https://www.storage.com/self-storage/idaho/" },
	{ abbv: "IL", fullName: "Illinois", url: "https://www.storage.com/self-storage/illinois/" },
	{ abbv: "IN", fullName: "Indiana", url: "https://www.storage.com/self-storage/indiana/" },
	{ abbv: "IA", fullName: "Iowa", url: "https://www.storage.com/self-storage/iowa/" },
	{ abbv: "KS", fullName: "Kansas", url: "https://www.storage.com/self-storage/kansas/" },
	{ abbv: "KY", fullName: "Kentucky", url: "https://www.storage.com/self-storage/kentucky/" },
	{ abbv: "LA", fullName: "Louisiana", url: "https://www.storage.com/self-storage/louisiana/" },
	{ abbv: "ME", fullName: "Maine", url: "https://www.storage.com/self-storage/maine/" },
	{ abbv: "MD", fullName: "Maryland", url: "https://www.storage.com/self-storage/maryland/" },
	{
		abbv: "MA",
		fullName: "Massachusetts",
		url: "https://www.storage.com/self-storage/massachusetts/"
	},
	{ abbv: "MI", fullName: "Michigan", url: "https://www.storage.com/self-storage/michigan/" },
	{ abbv: "MN", fullName: "Minnesota", url: "https://www.storage.com/self-storage/minnesota/" },
	{ abbv: "MS", fullName: "Mississippi", url: "https://www.storage.com/self-storage/mississippi/" },
	{ abbv: "MO", fullName: "Missouri", url: "https://www.storage.com/self-storage/missouri/" },
	{ abbv: "MT", fullName: "Montana", url: "https://www.storage.com/self-storage/montana/" },
	{ abbv: "NE", fullName: "Nebraska", url: "https://www.storage.com/self-storage/nebraska/" },
	{ abbv: "NV", fullName: "Nevada", url: "https://www.storage.com/self-storage/nevada/" },
	{
		abbv: "NH",
		fullName: "New Hampshire",
		url: "https://www.storage.com/self-storage/new-hampshire/"
	},
	{ abbv: "NJ", fullName: "New Jersey", url: "https://www.storage.com/self-storage/new-jersey/" },
	{ abbv: "NM", fullName: "New Mexico", url: "https://www.storage.com/self-storage/new-mexico/" },
	{ abbv: "NY", fullName: "New York", url: "https://www.storage.com/self-storage/new-york/" },
	{
		abbv: "NC",
		fullName: "North Carolina",
		url: "https://www.storage.com/self-storage/north-carolina/"
	},
	{
		abbv: "ND",
		fullName: "North Dakota",
		url: "https://www.storage.com/self-storage/north-dakota/"
	},
	{ abbv: "OH", fullName: "Ohio", url: "https://www.storage.com/self-storage/ohio/" },
	{ abbv: "OK", fullName: "Oklahoma", url: "https://www.storage.com/self-storage/oklahoma/" },
	{ abbv: "OR", fullName: "Oregon", url: "https://www.storage.com/self-storage/oregon/" },
	{
		abbv: "PA",
		fullName: "Pennsylvania",
		url: "https://www.storage.com/self-storage/pennsylvania/"
	},
	{
		abbv: "RI",
		fullName: "Rhode Island",
		url: "https://www.storage.com/self-storage/rhode-island/"
	},
	{
		abbv: "SC",
		fullName: "South Carolina",
		url: "https://www.storage.com/self-storage/south-carolina/"
	},
	{
		abbv: "SD",
		fullName: "South Dakota",
		url: "https://www.storage.com/self-storage/south-dakota/"
	},
	{ abbv: "TN", fullName: "Tennessee", url: "https://www.storage.com/self-storage/tennessee/" },
	{ abbv: "TX", fullName: "Texas", url: "https://www.storage.com/self-storage/texas/" },
	{ abbv: "UT", fullName: "Utah", url: "https://www.storage.com/self-storage/utah/" },
	{ abbv: "VT", fullName: "Vermont", url: "https://www.storage.com/self-storage/vermont/" },
	{ abbv: "VA", fullName: "Virginia", url: "https://www.storage.com/self-storage/virginia/" },
	{ abbv: "WA", fullName: "Washington", url: "https://www.storage.com/self-storage/washington/" },
	{
		abbv: "WV",
		fullName: "West Virginia",
		url: "https://www.storage.com/self-storage/west-virginia/"
	},
	{ abbv: "WI", fullName: "Wisconsin", url: "https://www.storage.com/self-storage/wisconsin/" },
	{ abbv: "WY", fullName: "Wyoming", url: "https://www.storage.com/self-storage/wyoming/" }
];
