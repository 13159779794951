import React from "react";
import PropTypes from "prop-types";
import Image from "@sparefoot/react-commons/Image";

import "./TrackingPixel.scss";

export default function TrackingPixel({ src }) {
	return (
		<Image
			className="tracking-pixel"
			src={src}
		/>
	);
}

TrackingPixel.propTypes = {
	src: PropTypes.string
};
