import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./Row.scss";

export default function Row({
	align,
	children,
	className,
	reverse,
	gutter = "medium",
	justify,
	largeGutter,
	mediumGutter,
	smallGutter
}) {
	return (
		<div
			className={classnames("page-row", className, {
				reverse,
				[`justify-${justify}`]: justify,
				[`align-${align}`]: align,
				[`g-${gutter}`]: gutter,
				[`sm-g-${smallGutter}`]: smallGutter,
				[`md-g-${mediumGutter}`]: mediumGutter,
				[`lg-g-${largeGutter}`]: largeGutter
			})}
		>
			{children}
		</div>
	);
}

Row.propTypes = {
	gutter: PropTypes.oneOf([
		"tiny",
		"small",
		"medium",
		"large",
		"xlarge",
		"xxlarge",
		"huge"
	]),
	smallGutter: PropTypes.oneOf([
		"tiny",
		"small",
		"medium",
		"large",
		"xlarge",
		"xxlarge",
		"huge"
	]),
	mediumGutter: PropTypes.oneOf([
		"tiny",
		"small",
		"medium",
		"large",
		"xlarge",
		"xxlarge",
		"huge"
	]),
	largeGutter: PropTypes.oneOf([
		"tiny",
		"small",
		"medium",
		"large",
		"xlarge",
		"xxlarge",
		"huge"
	]),
	align: PropTypes.oneOf(["center", "start", "end"]),
	justify: PropTypes.oneOf(["space-between", "start", "end", "center"]),
	children: PropTypes.node,
	className: PropTypes.string,
	reverse: PropTypes.bool
};
