import React from "react";
import ChevronSVG from "assets/icons/chevron.inline.svg";

import Icon from "./Icon";

const Chevron = ({ ...rest }) => (
	<Icon
		name="chevron"
		alt="Chevron"
		{...rest}
	>
		<ChevronSVG />
	</Icon>
);

export default Chevron;
