import React from "react";
import RoundedArrowSVG from "assets/icons/arrow-round.inline.svg";

import Icon from "./Icon";

const RoundedArrow = ({ ...rest }) => (
	<Icon
		name="Arrow"
		alt="Arrow"
		{...rest}
	>
		<RoundedArrowSVG />
	</Icon>
);

export default RoundedArrow;
