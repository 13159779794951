import { mergeWith } from "lodash";

function arrayHandler(objValue, srcValue) {
	if (Array.isArray(objValue)) {
		return srcValue;
	}

	return undefined;
}

export default function mergeEntities(bag = {}, entities) {
	return Object.keys(entities).reduce(
		(newBag, id) => ({
			...newBag,
			[id]: mergeWith(newBag[id], entities[id], arrayHandler)
		}),
		bag
	);
}
