import React from "react";
import MailIconSVG from "assets/icons/mail.inline.svg";
import Icon from "./Icon";

const Mail = ({ ...rest }) => (
	<Icon
		name="mail"
		alt="mail"
		{...rest}
	>
		<MailIconSVG />
	</Icon>
);

export default Mail;
