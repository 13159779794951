import PropTypes from "prop-types";

export const metaPropTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	canonical: PropTypes.string,
	links: PropTypes.arrayOf(PropTypes.object),
	noIndex: PropTypes.bool,
	favicon: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};
