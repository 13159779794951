import { PageTypes } from "@sparefoot/segment-react";

const amenityTypesFormatted = {
	"twenty-four-hour-access-storage-units": "24-hour-access",
	"climate-control-storage-units": "climate-controlled"
};

export default function buildLandingQuery(match, queryParams, landingType) {
	const { city, state } = match.params;
	const location = `${city}, ${state}`;
	const pageType =
		landingType === "city"
			? PageTypes.CITY_LANDING
			: PageTypes.AMENITY_LANDING;
	let query = {
		...queryParams,
		city,
		state,
		location,
		pageType
	};
	if (landingType === "amenity") {
		const { amenity } = match.params;
		const amenityFormatted = amenityTypesFormatted[amenity];
		const subPageType = amenityFormatted;
		query = { ...query, amenity: amenityFormatted, subPageType };
	}
	return query;
}
