import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
	visitId: null,
	traffic_source_id: null,
	siteId: 424,
	user_ip: null
};

export const userSlice = createSlice({
	name: "user",
	initialState
});

export default userSlice.reducer;
