import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ApplicationWrapper } from "containers/ApplicationWrapper";
import { ErrorBoundary } from "./ApplicationWrapper/Bugsnag";

import "../main.scss";

function App({ store }) {
	return (
		<Provider store={store}>
			<ErrorBoundary>
				<BrowserRouter>
					<ApplicationWrapper />
				</BrowserRouter>
			</ErrorBoundary>
		</Provider>
	);
}

App.propTypes = {
	store: PropTypes.object.isRequired
};

export default App;
