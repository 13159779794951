import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./Group.scss";

export default function Group({ children, className }) {
	return (
		<div className={classnames("page-group", className)}>{children}</div>
	);
}

Group.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string
};
