import qs from "query-string";
import { pickBy } from "lodash";
import { PATHS } from "config/paths";

export const sanitizeParams = (params) => pickBy(params, Boolean);

export const encodeQueryStringValue = (params) => {
	const sanitizedParams = sanitizeParams(params);
	return qs.stringify(sanitizedParams, {
		arrayFormat: "bracket"
	});
};

// trim trailing slash, otherwise last query param comes with a / baked in
export const decodeQueryStringValue = (search) =>
	qs.parse(search.replace(/\/$/, ""), {
		arrayFormat: "bracket"
	});

const buildUrl = (path, params) => {
	// remove trailing slash if it exists
	let url = `${path.replace(/\/$/, "")}`;
	if (params) {
		const encodedParams = encodeQueryStringValue(params);
		url = `${url}/?${encodedParams}`;
	}
	return url;
};

export default function buildSearchLink(params) {
	return buildUrl(PATHS.SEARCH, params);
}

export function buildSearchFilterLink(pathname, params) {
	return buildUrl(pathname, params);
}
