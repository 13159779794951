import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { get, isEmpty } from "lodash";
import { getSearchPage } from "store/modules/search";
import mergeEntities from "utils/store/mergeEntities";
import { getFacilityPage } from "./facilities";

// =============================================================================
// INITIAL STATE
// =============================================================================

export const initialState = {
	error: null,
	byId: {}
};

// =============================================================================
// REDUCER
// =============================================================================

const reducers = {};

// =============================================================================
// THUNKS
// =============================================================================

// =============================================================================
// EXTRA REDUCERS
// =============================================================================

const extraReducers = (builder) => {
	builder.addMatcher(
		isAnyOf(getSearchPage.fulfilled, getFacilityPage.fulfilled),
		(state, action) => {
			const reviews = get(action, "payload.entities.reviews");
			if (!isEmpty(reviews)) {
				state.byId = mergeEntities(state.byId, reviews);
			}
		}
	);
};

// =============================================================================
// SLICE
// =============================================================================

export const reviewsSlice = createSlice({
	name: "reviews",
	initialState,
	reducers,
	extraReducers
});

// =============================================================================
// SELECTORS
// =============================================================================

export default reviewsSlice.reducer;
