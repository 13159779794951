import React from "react";
import ParkingSpaceSVG from "assets/icons/parking-space.inline.svg";

import Icon from "./Icon";

const ParkingSpace = ({ ...rest }) => (
	<Icon
		name="parking-space"
		alt="The front of a car"
		{...rest}
	>
		<ParkingSpaceSVG />
	</Icon>
);

export default ParkingSpace;
