/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";

const COLORS = {
	// these match the colors defined in _variables.scss
	black: "#000000",
	blue: "#1c72e7",
	"dark gray": "#424242",
	gray: "#707070",
	green: "#1e8e3e",
	"light gray": "#a9a8a8",
	"light blue": "#08a0dd",
	"lightest blue": "#f3f7fc",
	white: "#ffffff",
	"dark blue": "#0b2c5a",
	orange: "#ef6922",
	"lightest orange": "#fdede4",
	red: "#cb010e"
};

const LoadingBoxes = ({ primaryColor, secondaryColor, ...rest }) => (
	<Icon
		name="loading-boxes"
		alt="Loading boxes animation"
		{...rest}
	>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlSpace="preserve"
			viewBox="0 0 67.22 64.76"
		>
			<path
				d="M45.61 5.06 39.9 1.7a12.26 12.26 0 0 0-12.27-.11l-5.78 3.27a12.29 12.29 0 0 0-6.23 10.57v6.63a12.25 12.25 0 0 0 6 10.68l5.71 3.37a12.27 12.27 0 0 0 12.28.11L45.37 33a12.29 12.29 0 0 0 6.23-10.62l.06-6.63a12.3 12.3 0 0 0-6.05-10.69ZM30 5.86a7.38 7.38 0 0 1 7.37.07l5.71 3.36a7 7 0 0 1 1.22.93l-10.85 6.16-10.57-6.22a7.47 7.47 0 0 1 1.39-1Zm-5.9 22.65a7.34 7.34 0 0 1-3.62-6.4l.05-6.64a7.45 7.45 0 0 1 .08-1l10.52 6.2L31 32.44a6.64 6.64 0 0 1-1.21-.56Zm22.59-6.18A7.37 7.37 0 0 1 43 28.68l-5.77 3.26a7.56 7.56 0 0 1-1.24.55L36 20.56l10.62-6a8.77 8.77 0 0 1 .09 1.15Z"
				fill={primaryColor}
			>
				<animate
					attributeName="fill"
					begin="0s"
					dur=".9s"
					values={`${COLORS[primaryColor]};${COLORS[secondaryColor]};${COLORS[primaryColor]}`}
					calcMode="linear"
					repeatCount="indefinite"
				/>
			</path>
			<path
				d="m61.18 32-5.72-3.36a12.25 12.25 0 0 0-12.27-.11l-5.77 3.27a12.28 12.28 0 0 0-6.23 10.58L31.13 49a12.26 12.26 0 0 0 6.05 10.68l5.71 3.37a12.3 12.3 0 0 0 12.27.11l5.77-3.27a12.25 12.25 0 0 0 6.23-10.57l.06-6.64A12.27 12.27 0 0 0 61.18 32Zm-15.57.8a7.36 7.36 0 0 1 7.36.07l5.72 3.36a7.42 7.42 0 0 1 1.22.93L49 43.33l-10.55-6.22a7.47 7.47 0 0 1 1.39-1Zm-5.94 22.66A7.36 7.36 0 0 1 36 49.05l.06-6.63a7.75 7.75 0 0 1 .07-1l10.53 6.2-.1 11.73a7.9 7.9 0 0 1-1.22-.56Zm22.58-6.18a7.36 7.36 0 0 1-3.73 6.35l-5.77 3.26a6.56 6.56 0 0 1-1.25.54l.11-11.93 10.61-6a6.3 6.3 0 0 1 .09 1.15Z"
				fill={COLORS[primaryColor]}
			>
				<animate
					attributeName="fill"
					begin=".3s"
					dur=".9s"
					values={`${COLORS[primaryColor]};${COLORS[secondaryColor]};${COLORS[primaryColor]}`}
					calcMode="linear"
					repeatCount="indefinite"
				/>
			</path>
			<path
				d="m30.05 32-5.72-3.37a12.28 12.28 0 0 0-12.27-.11l-5.77 3.29A12.27 12.27 0 0 0 .06 42.38L0 49a12.29 12.29 0 0 0 6 10.7l5.72 3.36a12.26 12.26 0 0 0 12.28.11l5.8-3.27A12.26 12.26 0 0 0 36 49.33l.06-6.63A12.27 12.27 0 0 0 30.05 32Zm-15.57.8a7.36 7.36 0 0 1 7.36.06l5.71 3.37a7.44 7.44 0 0 1 1.23.92l-10.9 6.17-10.56-6.21a7.4 7.4 0 0 1 1.38-1ZM8.54 55.47a7.37 7.37 0 0 1-3.63-6.41L5 42.43a7.48 7.48 0 0 1 .07-1l10.53 6.2-.11 11.72a7.33 7.33 0 0 1-1.21-.56Zm22.58-6.18a7.35 7.35 0 0 1-3.74 6.34l-5.77 3.27a8 8 0 0 1-1.24.54l.11-11.93 10.61-6a7.46 7.46 0 0 1 .09 1.16Z"
				fill={COLORS[primaryColor]}
			>
				<animate
					attributeName="fill"
					begin=".6s"
					dur=".9s"
					values={`${COLORS[primaryColor]};${COLORS[secondaryColor]};${COLORS[primaryColor]}`}
					calcMode="linear"
					repeatCount="indefinite"
				/>
			</path>
		</svg>
	</Icon>
);

LoadingBoxes.propTypes = {
	primaryColor: PropTypes.oneOf(Object.keys(COLORS)),
	secondaryColor: PropTypes.oneOf(Object.keys(COLORS))
};

LoadingBoxes.defaultProps = {
	primaryColor: "blue",
	secondaryColor: "white"
};

export default LoadingBoxes;
